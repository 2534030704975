/* .contact_us_container{
    background-image: url("./../assets/images/contact-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0px;
    background-position: center;
} */

/* hero slider image start  */
.slider_container{
  height: calc(100vh - 80px);
  width: 100%;
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:calc(50% - 0px) ;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
/* hero slider image end */


/* tech news page start  */
/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* tech news page end */